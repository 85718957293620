<template>
  <v-row id="update-profile-page">
    <v-col cols="12">
        <v-card>
            <v-row class="ma-0 py-3" align="center" justify="center">
                <v-card-title class="text-h5 primary--text font-weight-bold d-inline-block pa-0 title-border">
                    Update Profile
                </v-card-title>
            </v-row>
            <v-card-text>
                <v-form lazy-validation @submit.prevent="saveProfile">
                    <v-row class="ma-0 pa-0">
                        <v-col cols="12" class="col-lg-6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">First Name</v-subheader>
                            <v-text-field  outlined ref="first_name" hide-details="auto" v-model="model.first_name" :error-messages="$helpers.errorMsg('name', $v.model.first_name, 'First Name')"
                                placeholder="First Name" @input="$v.model.first_name.$touch()"  @blur="$v.model.first_name.$touch()" required/>
                        </v-col>
                        <v-col cols="12" class="col-lg-6">
                            <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Last Name</v-subheader>                     
                            <v-text-field outlined ref="last_name" hide-details="auto" v-model="model.last_name" :error-messages="$helpers.errorMsg('name', $v.model.last_name, 'Last Name')"
                                placeholder="Last Name" @input="$v.model.last_name.$touch()"  @blur="$v.model.last_name.$touch()" required/>
                        </v-col>
                    </v-row>

                    <v-col cols="12">
                        <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">E-mail</v-subheader> 
                        <v-text-field outlined v-model="model.email" hide-details="auto" :error-messages="$helpers.errorMsg('email', $v.model.email, 'Email')"
                            placeholder="E-mail" ref="email" @input="$v.model.email.$touch()"  @blur="$v.model.email.$touch()" required />
                    </v-col>
                    
                    <v-col cols="12">
                        <v-subheader class="primary--text pa-0 ma-0 h-auto mb-1 text-capitalize">Password</v-subheader> 
                        <v-text-field  outlined ref="password" hide-details="auto" :type="show ? 'text' : 'password'" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                            v-model="model.password" :error-messages="$helpers.errorMsg('name', $v.model.password, 'Password')"
                            placeholder="Password" @click:append="show = !show" required />
                    </v-col>

                    <div class="d-block d-lg-flex justify-end my-3">
                        <v-btn type="submit" color="primary">
                            update profile
                        </v-btn>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";

export default {
  name: "updateProfile",
  metaInfo: {
    title: "Update Profile",
  },
  data: (vm) => ({
    show: false,
    model: vm.$helpers.getCurData('curUser') || {},
  }),
  validations: {
     model: {
        first_name: { required},
        last_name: { required},
        email: { email, required},
        password: { required},
    }
  },
  methods: {
    saveProfile() {
        this.$v.$touch();
        if (this.$v.$invalid) {
            this.$helpers.focusErrorElement(this.$v.model, this.$refs);
            return;
        }
        // this.updateUserData(this.model).then((response) => {
        //     if (!response || !response.data) {
        //         return;
        //     }
        this.model.name = `${this.model.first_name} ${this.model.last_name}`;
        this.$helpers.lsPush("curUser", this.model);
        this.$store.commit("snackbar/SHOW_MESSAGE", {
            text: "Profile Updated Successfully",
            color: "success",
        });
        this.$router.push('/');
        // }).catch(() => {});
    }
  }
}
</script>

<style>

</style>